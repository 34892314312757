<template>
  <div class="home" style="background: rgba(242, 245, 250, 1);">
    <div class="about-banner">
      <p class="about-p1">快企务</p>
      <p class="about-p2">专业的涉内外企业服务平台</p>
      <video class="about-video" controls src="https://www.kuaiqiwu.com/video.mp4"></video>
    </div>
    <div class="box about-box1" style="background: #fff;">
      <p class="box-p1">关于快企务</p>
      <p class="box-p2" style="width: 92%;">“快企务”是深圳金百利国际商务顾问有限公司旗下的涉内外企业服务平台，是业内领先的企业商服服务商，主要服务于全球企业客户，为客户提供全球公司（办事处）设立、公司税筹、公证认证、公司秘书、知识产权、法律合规、资金出入境（合规）等业务。目前已成功服务客户多达万家。</p>
      <p class="box-p2" style="width: 92%;">深圳金百利国际商务顾问有限公司成立于2010年5月，已在<span style="color: rgba(47, 114, 235, 1);font-weight: bold;">上海、北京、广州、深圳、香港、伦敦</span>设立分公司，拥有一支上百人的专业团队</p>
      <div class="about-box1-top">
        <div class="about-top-inner">
          <p class="about-top-p1">100,000+</p>
          <p class="about-top-p2">服务客户</p>
        </div>
        <div class="about-top-inner">
          <p class="about-top-p1">200,000+</p>
          <p class="about-top-p2">累计成交</p>
        </div>
        <div class="about-top-inner">
          <p class="about-top-p1">10,000+</p>
          <p class="about-top-p2">合作企业</p>
        </div>
      </div>
      <p class="box-p1">公司服务理念</p>
      <div class="about-box1-bottom">
        <div class="about-bottom-inner">
          <img class="about-bottom-icon" src="../assets/img/about/icon_1_1.png" alt="">
          <p class="about-bottom-p">客户有问题，我们有方案</p>
        </div>
        <div class="about-bottom-inner">
          <img class="about-bottom-icon" src="../assets/img/about/icon_1_2.png" alt="">
          <p class="about-bottom-p">注册公司，仅仅是我们服务客户的开始</p>
        </div>
        <div class="about-bottom-inner">
          <img class="about-bottom-icon" src="../assets/img/about/icon_1_3.png" alt="">
          <p class="about-bottom-p">非公司注册，希望我们跟客户有一个好的开始</p>
        </div>
        <div class="about-bottom-inner">
          <img class="about-bottom-icon" src="../assets/img/about/icon_1_4.png" alt="">
          <p class="about-bottom-p">让客户满意，给客户体验100分</p>
        </div>
        <div class="about-bottom-inner">
          <img class="about-bottom-icon" src="../assets/img/about/icon_1_5.png" alt="">
          <p class="about-bottom-p">不让客户受损失，过错我们买单</p>
        </div>
      </div>
    </div>
    <div class="box development">
      <p class="box-p1">发展历程</p>
      <div class="development-box">
        <p class="development-p1">2010年</p>
        <p class="development-p2">2010年5月，公司于深圳福田区成立。</p>
        <p class="development-p1">2014年</p>
        <p class="development-p2">2014年，公司注册了“离岸岛”品牌，开启了服务企业用户的专业化品牌之路。</p>
        <p class="development-p1">2017年</p>
        <p class="development-p2">2017年12月，公司在上海，设立第一家分公司；上海作为国际金融中心，是优质外资企业的集中点，公司在持续性发展战中占据重要位置。</p>
        <p class="development-p1">2018年</p>
        <p class="development-p2">2018年5月，公司成立8周年，已成功转型成为一家专业的涉外综合服务商。</p>
        <p class="development-p2">2018年6月，公司引进双重加密系统，切实为保障客户信息下功夫。</p>
        <p class="development-p2">2018年9月，公司在北京设立分公司，北京作为国家政治中心，大部分使馆也集中在北京，将有利于第一时间完成客户所需，为服务提速提质。</p>
        <p class="development-p1">2020年</p>
        <p class="development-p2">2020年1月，全新品牌“快企务”正式上线，实现双品牌战略发展。同时公司持续开拓新业务，进军知识产权及国内工商业务，以更全面服务客户。</p>
        <p class="development-p1">2021年</p>
        <p class="development-p2">2021年6月获得2020年度<span>“广东省守合同重信用企业”</span>称号。</p>
        <p class="development-p2">2021年获得2020年度<span>“广东省A级企业纳税单位”</span>称号。</p>
        <p class="development-p2">2021年11月，公司在大湾区广州成立子公司。目前，公司已有香港、深圳、上海、北京、广州五地公司。</p>
      </div>
    </div>
    <div class="box about-case">
      <p class="box-p1">客户案例</p>
      <p class="box-p2">快企务年服务企业高达
        <span style="color: rgba(47, 114, 235, 1);font-weight: bold;">10,000+</span>且还在不断上升，长期合作知名上市企业
        <span style="color: rgba(47, 114, 235, 1);font-weight: bold;">200+</span>
      </p>
      <div class="about-case-box">
        <img class="about-case-img" src="../assets/img/about/pic_2.jpg" alt="">
      </div>
    </div>
    <div class="box about-team">
      <p class="box-p1">快乐实干的团队</p>
      <div class="lunbo-box" style="height: 218px;margin-top: 20px;">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_1.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_2.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_3.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_4.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_5.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_6.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_7.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_8.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_9.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_10.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_11.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_12.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_13.jpg" alt="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="swiper-img" style="height: 218px;" src="../assets/img/about/pic_3_14.jpg" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box about-adv">
      <p class="box-p1">快企务优势</p>
      <p class="box-p2">“洞察客户需求，让客户满意”是公司成立15年以来的执着，因此我们收获了上万家客户的支持，也帮助了上万家客户解决了相关问题。未来我们将继续走品牌发展之路，成为行业领先的企业服务品牌。</p>
      <div class="lunbo-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :width="248" :loop="true">
          <van-swipe-item>
            <div class="about-adv-inner">
              <img class="about-adv-img" src="../assets/img/about/pic_4_1.jpg" alt="">
              <div class="about-adv-bottom">
                <p class="about-adv-p1">快</p>
                <p class="about-adv-p2">“快“是快企务的灵魂，也是快企务的挑战， 我们穷尽一切办法，利用一切资源，力求给客户提供最快的解决方案，如我们设立一家开曼公司仅需要20小时，完成一份文件认证仅需要30分钟。而回答客户的问题仅需0秒。</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="about-adv-inner">
              <img class="about-adv-img" src="../assets/img/about/pic_4_2.jpg" alt="">
              <div class="about-adv-bottom">
                <p class="about-adv-p1">全</p>
                <p class="about-adv-p2">当客户找到我们时，已为客户准备了所需要的全套产品和服务内容，信心来自于100多个国家的渠道已打通，13年的企服经验和100多人的团队协同。如客户成立公司到上市所需要的相关配套业务我们介可提供。</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="about-adv-inner">
              <img class="about-adv-img" src="../assets/img/about/pic_4_3.jpg" alt="">
              <div class="about-adv-bottom">
                <p class="about-adv-p1">进</p>
                <p class="about-adv-p2">追求“进”步从未停止，13年征程我们如履薄冰也开放从容，客户的服务进步了，产品优化进步了，合规尽调进步了，员工的工作能力进步了，客户的满意度进一步提升。如我们客户的留存率一直稳定在92.6%以上。</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="about-adv-inner">
              <img class="about-adv-img" src="../assets/img/about/pic_4_4.jpg" alt="">
              <div class="about-adv-bottom">
                <p class="about-adv-p1">退</p>
                <p class="about-adv-p2">可“退”款承诺在13年以前成立公司之时就写进了服务条款，13年的坚持传承从未改变。满足不了客户的需求，解决不了客户的问题，我们认为是自己的过错，给客户退款不让客户遭受任何损失，同时也是对我们专业的信心。</p>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="box about-link">
      <p class="box-p1">联系我们</p>
      <p class="box-p2">专业、高效、敬业的团队为您服务</p>
      <div class="tab-box" style="width: 100%;">
        <div class="tab-inner" style="font-size: 12px;" :class="{'active': tab == 1}" @click="tab = 1">深圳总部</div>
        <div class="tab-inner" style="font-size: 12px;" :class="{'active': tab == 2}" @click="tab = 2">北京分部</div>
        <div class="tab-inner" style="font-size: 12px;" :class="{'active': tab == 3}" @click="tab = 3">上海分部</div>
        <div class="tab-inner" style="font-size: 12px;" :class="{'active': tab == 4}" @click="tab = 4">广州分部</div>
        <div class="tab-inner" style="font-size: 12px;" :class="{'active': tab == 5}" @click="tab = 5">香港分部</div>
        <div class="tab-inner" style="font-size: 12px;" :class="{'active': tab == 6}" @click="tab = 6">伦敦办公室</div>
      </div>
      <div class="map-box">
        <div class="map-inner" v-if="tab == 1">
          <img class="map-img" src="../assets/img/about/pic_5_1shenzhen.jpg" alt="">
          <div class="guide-box">
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_1.png" alt="">
              地址：深圳福田区福中三路1006号诺德中心36AF</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_2.png" alt="">
              电话：400-860-5600</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_3.png" alt="">
              地铁：市民中心站（4号线、2/8号线）；</p>
            <p class="guide-p1">岗厦北站（2、10、11、12号线）</p>
            <p class="guide-p1">公交：市民中心东站（34、38、41、60、64、M192、            M347、M390、M454、M459、M500、            M588路，高峰专线16号，高快巴士28路）            海田路口站（B709路）</p>
            <p class="guide-tips">（大厦内停车位充足，如您需要，可联系我们的咨询顾问帮您预约车位）</p>
          </div>
        </div>
        <div class="map-inner" v-if="tab == 2">
          <img class="map-img" src="../assets/img/about/pic_5_2beijing.jpg" alt="">
          <!-- <div class="guide-box">
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_1.png" alt="">
              地址：深圳福田区福中三路1006号诺德中心12A,12F</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_2.png" alt="">
              电话：0755-22655612</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_3.png" alt="">
              地铁：市民中心站（4号线、2/8号线）；</p>
            <p class="guide-p1">岗厦北站（2、10、11、12号线）</p>
            <p class="guide-p1">公交：市民中心东站（34、38、41、60、64、M192、            M347、M390、M454、M459、M500、            M588路，高峰专线16号，高快巴士28路）            海田路口站（B709路）</p>
            <p class="guide-tips">（大厦内停车位充足，如您需要，可联系我们的咨询顾问帮您预约车位）</p>
          </div> -->
        </div>
        <div class="map-inner" v-if="tab == 3">
          <img class="map-img" src="../assets/img/about/pic_5_3shanghai.jpg" alt="">
          <!-- <div class="guide-box">
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_1.png" alt="">
              地址：深圳福田区福中三路1006号诺德中心12A,12F</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_2.png" alt="">
              电话：0755-22655612</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_3.png" alt="">
              地铁：市民中心站（4号线、2/8号线）；</p>
            <p class="guide-p1">岗厦北站（2、10、11、12号线）</p>
            <p class="guide-p1">公交：市民中心东站（34、38、41、60、64、M192、            M347、M390、M454、M459、M500、            M588路，高峰专线16号，高快巴士28路）            海田路口站（B709路）</p>
            <p class="guide-tips">（大厦内停车位充足，如您需要，可联系我们的咨询顾问帮您预约车位）</p>
          </div> -->
        </div>
        <div class="map-inner" v-if="tab == 4">
          <img class="map-img" src="../assets/img/about/pic_5_4guangzhou.jpg" alt="">
          <!-- <div class="guide-box">
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_1.png" alt="">
              地址：深圳福田区福中三路1006号诺德中心12A,12F</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_2.png" alt="">
              电话：0755-22655612</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_3.png" alt="">
              地铁：市民中心站（4号线、2/8号线）；</p>
            <p class="guide-p1">岗厦北站（2、10、11、12号线）</p>
            <p class="guide-p1">公交：市民中心东站（34、38、41、60、64、M192、            M347、M390、M454、M459、M500、            M588路，高峰专线16号，高快巴士28路）            海田路口站（B709路）</p>
            <p class="guide-tips">（大厦内停车位充足，如您需要，可联系我们的咨询顾问帮您预约车位）</p>
          </div> -->
        </div>
        <div class="map-inner" v-if="tab == 5">
          <img class="map-img" src="../assets/img/about/pic_5_5hongkong.jpg" alt="">
          <!-- <div class="guide-box">
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_1.png" alt="">
              地址：深圳福田区福中三路1006号诺德中心12A,12F</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_2.png" alt="">
              电话：0755-22655612</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_3.png" alt="">
              地铁：市民中心站（4号线、2/8号线）；</p>
            <p class="guide-p1">岗厦北站（2、10、11、12号线）</p>
            <p class="guide-p1">公交：市民中心东站（34、38、41、60、64、M192、            M347、M390、M454、M459、M500、            M588路，高峰专线16号，高快巴士28路）            海田路口站（B709路）</p>
            <p class="guide-tips">（大厦内停车位充足，如您需要，可联系我们的咨询顾问帮您预约车位）</p>
          </div> -->
        </div>
        <div class="map-inner" v-if="tab == 6">
          <img class="map-img" src="../assets/img/about/pic_5_6london.jpg" alt="">
          <!-- <div class="guide-box">
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_1.png" alt="">
              地址：深圳福田区福中三路1006号诺德中心12A,12F</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_2.png" alt="">
              电话：0755-22655612</p>
            <p class="guide-p">
              <img class="guide-icon" src="../assets/img/about/icon_2_3.png" alt="">
              地铁：市民中心站（4号线、2/8号线）；</p>
            <p class="guide-p1">岗厦北站（2、10、11、12号线）</p>
            <p class="guide-p1">公交：市民中心东站（34、38、41、60、64、M192、            M347、M390、M454、M459、M500、            M588路，高峰专线16号，高快巴士28路）            海田路口站（B709路）</p>
            <p class="guide-tips">（大厦内停车位充足，如您需要，可联系我们的咨询顾问帮您预约车位）</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      activeName: '1',
      Dialog: false,
      tab: 1
    }
  },
 
  mounted(){
    window.scrollTo(0,0);
  },
  methods:{
    toAsk(){
      window.open('https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn','_blank')
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>
<style scoped>
  .about-banner{
    background: url('../assets/img/about/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
